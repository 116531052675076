import React from "react"
import {Link, Trans} from 'gatsby-plugin-react-i18next';
import "./footer.css"

const Footer = () => (
  <div className="footerRoot" >

  <div className="footerNav">
  <div className="footerContainer">

      <div className="footerContact">
        <div className="footerText">
        <h2> <Trans>Weiterleitung</Trans>
        </h2></div>

        <a href="mailto:info@hdieffendahl.com?subject=Ihre Anfrage / Your Request" className="footerButton">
        <h1><Trans>WeiterleitungButton</Trans></h1>
        </a>


      </div>

    <div className="footerLinks">
      <Link to="/impressum"><Trans>Impressum</Trans></Link>
      <Link to="/datenschutz"><Trans>Datenschutz</Trans></Link>
      <div>© {new Date().getFullYear()} Holger Dieffendahl</div>
    </div>

  </div>
  </div>
  </div>
)

export default Footer
