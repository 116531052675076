import PropTypes from "prop-types"
import React from "react"
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import Logo from './logo';
import './header.css';

const Header = ({ siteTitle }) => {

  const {language, originalPath} = useI18next();

  return (
  <header className="headerRoot">
  <nav className="headerNav">

      <div className="headerContainer">
        <div className="headerLogo">
            <Link
              to="/"
            >
            <Logo/>
            </Link>
        </div>
        <div className="headerLinks">

                {language === 'de' &&
                <div>

                <AnchorLink to="/#profil" title="Profil"/>

                <AnchorLink to="/downloads" title="Downloads"/>

                <AnchorLink to="/#service" title="Service"/>

                <a href="mailto:info@hdieffendahl.com?subject=Ihre Anfrage / Your Request" className="headerButton">
                Kontakt
                </a>
                </div>
                }

                {language === 'en' &&
                <div>

                <AnchorLink to="/en/#profile" title="Profile"/>

                <AnchorLink to="/downloads" title="Downloads"/>

                <AnchorLink to="/en/#services" title="Services"/>

                <a href="mailto:info@hdieffendahl.com?subject=Ihre Anfrage / Your Request" className="headerButton">
                Contact
                </a>

                </div>
                }

                <div className="headerLangLinks">


                {language === 'de' &&
                  <Link to={originalPath} language='en'>
                    EN
                  </Link>
                }
                {language === 'en' &&
                  <Link to={originalPath} language='de'>
                    DE
                  </Link>
                }
                </div>
        </div>
      </div>

  </nav>
  </header>
) }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
